import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function SowparnikaReadMore() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <h4 className='m-0'>Sowparnika Developers</h4>
            
            <p className="mb-0">
                <p className="AboutExpoPara">Working in sync with the Prime Minister's Housing Scheme, "Homes for All by 2025", Sowparnika has been delivering quality homes to all. We have redefined the concept of quality housing. With its headquarters in Bangalore, Sowparnika Projects is a leading real estate developer in the Southern part of India. We believe in developing in-house competencies. Hence, we have a customized team for Design, Quality Check, Electrical & Plumbing, Block Work, and Fabrication. They monitor and evaluate the progress from the time of construction to the delivery of the project. We aim to incorporate the concept of Lean Six Sigma across all processes in our organization. We at Sowparnika have laid a foundation solely based on our core values: Commitment, Customer Satisfaction and Teamwork.</p>
            </p>

            {isShowMore && (
                 <p className="mb-0">
                <h6>Vision</h6>
                <p className="AboutExpoPara">To be among the top 10 preferred brands in the Housing & Infrastructure arena, by Delivering Happiness and Value for a Life Time.</p>

                <h6>Mission</h6>
                <p className="AboutExpoPara">●	Become one of the top ten housing & infrastructure companies in South India</p>
                                                    <p className="AboutExpoPara">●	Achieve 1000 Crores Turnover and a successful IPO by 2025</p>
                                                    <p className="AboutExpoPara">●	Provide high-quality value homes through continuous innovations, improvements, and timely delivery</p>
                                                    <p className="AboutExpoPara">●	Create a culture of a consistent growth</p>
                                                    <p className="AboutExpoPara">●	Offer a transparent work environment that enhances employee excellence.
                                                    </p>
            </p>
            )}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default SowparnikaReadMore;