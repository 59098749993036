import React, { useState } from "react"
import "./assets/styles/_index.scss"
import SowparnikaAboutSidebar from "./SowparnikaAboutSidebar"
import Container from 'react-bootstrap/Container'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import { Link } from "gatsby"
import ProjectCtaButton from "../../projectctabutton/ProjectCtaButton"
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons'
import { Accordion, Card, Button } from 'react-bootstrap'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import VideoPlayer from "../../videoplayer/VideoPlayer"
import Slider from "react-slick"
import ProptvStand from "../../proptvstand/ProptvStand"

const videoUrlStand = "https://www.propverse.ai/utility/assets/homepage/HallProptv.mp4"
const thumbnailUrlStand = require("/utility/assets/homepage/prop-tv.png")

const videoUrl = "https://www.propverse.ai/utility/assets/homepage/HallProptv.mp4"
const thumbnailUrl = require("/utility/assets/common/video-thumbnail.png")

const propGpt = require("/utility/assets/common/propGPT.png")
const project = require("/utility/assets/icons/project-list.png")
const about = require("/utility/assets/icons/about-developer.png")
const enquiry = require("/utility/assets/icons/enquiry.png")

function SowparnikaAbout() {
    const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(true);

    const toggleRightSidebar = () => {
        setIsRightSidebarOpen(isRightSidebarOpen);
    };

    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerPadding: "0px",
        centerMode: true,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: false,
                    centerMode: true,
                    centerPadding: "0px",
                    slidesToShow: 1,
                },
            },

        ],

    }

    return (
        <>

            <div className="ProjectAboutPageSectionSowparnika">
                <Container fluid className="BoothBackSection d-none d-md-none">
                    <Link to="/developers/sowparnika-developer/">
                        <ArrowLeft /> Back
                    </Link>

                    <div className="EnquiryBoothSection d-block d-md-none">
                        <Image src={enquiry} fluid className="" />
                    </div>
                </Container>

                <div className="d-none d-md-block">
                    <SowparnikaAboutSidebar
                        isOpen={isRightSidebarOpen}
                        toggleRightSidebar={toggleRightSidebar}
                    />
                </div >

                <ProjectCtaButton />


                <div className="proptv-stand-wrapper d-none d-md-block">
                    <ProptvStand
                        videoUrlStand={videoUrlStand}
                        thumbnailUrlStand={thumbnailUrlStand}
                    />
                </div>
                <Container fluid className="ProjectAboutDetailFooterSection">
                    <Row>
                        <Col lg={4} className="p-0 textAlignCenter">
                        </Col>
                        <Col lg={4} className="p-0 textAlignCenter">
                            <Row className="ProjectAboutDetailListBg d-none d-md-block">
                                <Col lg={6} xs={7} className="p-0 pr-3 textAlignCenter">
                                    <div className="ProjectAboutDetailList">
                                        <Link to="/developers/sowparnika-developer/">
                                            <p><Image src={project} fluid className="" /> Project List</p>
                                        </Link>
                                    </div>
                                </Col>
                                <Col lg={6} xs={7} className="p-0 pr-3 pr-md-0 textAlignCenter">
                                    <div className="AboutDev">
                                        <Link to="/developers/sowparnika-developer/about/">
                                            <p><Image src={about} fluid className="" /> About&nbsp;Developer</p>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} className="p-0 textAlignRight">
                            <Image src={propGpt} fluid className="BoothLogoSection" />
                        </Col>
                    </Row>
                </Container>

                <Accordion defaultActiveKey="0" className="d-block d-md-none ProjectListAccordion ProjectDetailsList">

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                Sowparnika Developers
                            </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Tabs
                                    defaultActiveKey="aboutus"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="aboutus" title="About Us">
                                        <div className="ReadMoreSection">
                                            <h4 className='m-0'>Sowparnika Developers</h4>
                                            <p className="mb-0">
                                                <p className="AboutExpoPara">Working in sync with the Prime Minister's Housing Scheme, "Homes for All by 2025", Sowparnika has been delivering quality homes to all.</p>
                                            </p>

                                            {isShowMore && (
                                                <p className="mb-0">

                                                    <p className="AboutExpoPara">We have redefined the concept of quality housing. With its headquarters in Bangalore, Sowparnika Projects is a leading real estate developer in the Southern part of India. We believe in developing in-house competencies. Hence, we have a customized team for Design, Quality Check, Electrical & Plumbing, Block Work, and Fabrication. They monitor and evaluate the progress from the time of construction to the delivery of the project. We aim to incorporate the concept of Lean Six Sigma across all processes in our organization. We at Sowparnika have laid a foundation solely based on our core values: Commitment, Customer Satisfaction and Teamwork.</p>

                                                    <h6>Vision</h6>
                                                    <p className="AboutExpoPara">To be among the top 10 preferred brands in the Housing & Infrastructure arena, by Delivering Happiness and Value for a Life Time.</p>

                                                    <h6>Mission</h6>
                                                    <p className="AboutExpoPara">●	Become one of the top ten housing & infrastructure companies in South India</p>
                                                    <p className="AboutExpoPara">●	Achieve 1000 Crores Turnover and a successful IPO by 2025</p>
                                                    <p className="AboutExpoPara">●	Provide high-quality value homes through continuous innovations, improvements, and timely delivery</p>
                                                    <p className="AboutExpoPara">●	Create a culture of a consistent growth</p>
                                                    <p className="AboutExpoPara">●	Offer a transparent work environment that enhances employee excellence.
                                                    </p>
                                                </p>
                                            )}
                                            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>

                                            <div className="AboutHallSection">
                                                <Button>Click here to download brochure</Button>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="video" title="Video">
                                        <section className="pt-0">
                                            <Container className="p-0">
                                                <Slider {...settings} className="">
                                                    <div className="ProjectListAccordionSlider">
                                                        <VideoPlayer
                                                             videoUrl={videoUrl}
                                                            thumbnailUrl={thumbnailUrl}
                                                        />
                                                    </div>
                                                </Slider>
                                            </Container>
                                        </section>
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>
            </div >
        </>
    )
}

export default SowparnikaAbout